import React, { useContext } from 'react';
import FunnelContext from '../../../context/FunnelContext';
import useLocalStorage from '../../../hooks/useLocalStorage';

const AnswerInput = props => {
  const { answers, setAnswers, extraObjects, setExtraObjects } = useContext(
    FunnelContext
  );

  const [answersLS, setAnswersLS] = useLocalStorage('answers', {});

  const defaultValue = answersLS[props.name] || props.defaultValue;

  const handleChange = event => {
    const targetValue = event.target.value;
    if (!props.name) {
      return;
    }

    let value = '';
    switch (props.type) {
      case 'checkbox':
        const currentAnswer = answers[props.name]
          ? answers[props.name].split(',')
          : [];
        if (event.target.checked) {
          currentAnswer.push(targetValue);
        } else {
          currentAnswer.pop(targetValue);
        }
        value = Array.from(new Set(currentAnswer)).join(',');
        if (props.clearOldValue) {
          value = targetValue;
        }
        break;
      default:
        value = targetValue;
        break;
    }
    const newAnswers = Object.assign(answers, {
      [props.name]: value
    });
    setAnswers(newAnswers);
    if (props.addToExtraObjects) {
      const newExtraObjects = Object.assign(extraObjects, {
        [props.name]: value
      });
      setExtraObjects(newExtraObjects);
    }

    const newAnswersLS = Object.assign(answersLS, {
      [props.name]: value
    });
    setAnswersLS(newAnswersLS);
  };

  return (
    <input
      key={props.name}
      name={props.name}
      placeholder={props.placeholder}
      type={props.type}
      value={props.value}
      defaultValue={defaultValue}
      required={props.required}
      {...props.attributes}
      onChange={handleChange}
    />
  );
};

export default AnswerInput;
